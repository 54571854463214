import {Box} from "@mui/material";
import { Container, Typography, Paper, Grid, TextField, Button } from '@mui/material';
import { Phone, Email, LocationOn } from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const ContactUs = () => {
    return (
        <Container>
            {/* Заголовок страницы */}
            <Box sx={{ mt: 5, mb: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    Контакты
                </Typography>
            </Box>

            {/* Информация о контактах */}
            <Box mb={4}>
                <Paper elevation={3} sx={{ padding: 4, borderRadius: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Свяжитесь с нами
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Мы всегда рады ответить на любые вопросы. Вы можете связаться с нами по телефону, электронной почте или посетить наш офис.
                    </Typography>

                    {/* Контактная информация */}
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4} display="flex" alignItems="center">
                            <Phone color="primary" sx={{ fontSize: 40, mr: 2 }} />
                            <Typography variant="body1">
                                Телефон: <br />
                                +77057747760
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4} display="flex" alignItems="center">
                            <Email color="primary" sx={{ fontSize: 40, mr: 2 }} />
                            <Typography variant="body1">
                                Электронная почта: <br />
                                info@topmarketing.kz
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4} display="flex" alignItems="center">
                            <LocationOn color="primary" sx={{ fontSize: 40, mr: 2 }} />
                            <Typography variant="body1">
                                Адрес: <br />
                                Казахстан, г. Астана, ул. Сарайшык 34
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

            {/* Форма обратной связи */}
            <Box mb={5}>
                <Paper elevation={3} sx={{ padding: 4, borderRadius: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Отправить сообщение
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Используйте форму ниже, чтобы отправить нам сообщение. Мы свяжемся с вами как можно скорее.
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Ваше имя" variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Ваша электронная почта" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Тема" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Сообщение"
                                variant="outlined"
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Button variant="contained" color="primary" size="large">
                                Отправить сообщение
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

            {/* Карта (например, Google Maps) */}
            <Box mb={5}>
                <Paper elevation={3} sx={{ padding: 4, borderRadius: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Мы на карте
                    </Typography>
                    {/* Здесь можно вставить карту с помощью iFrame или Google Maps API */}
                    <Box sx={{ mt: 2 }}>
                        <iframe
                            title="Карта"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.5767382876!2d76.74183770000001!3d43.2220156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x388369d6e36f084b%3A0xd19b15737fdf5e84!2sAlmaty!5e0!3m2!1sen!2skz!4v1632971149231!5m2!1sen!2skz"
                            width="100%"
                            height="400"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </Box>
                </Paper>
            </Box>
        </Container>
    )
}

export default ContactUs;
import {Box, Container, Grid, Link, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {grey} from "@mui/material/colors";

const Footer = () => {

    const navigate = useNavigate();

    return (
        <Box sx={{ backgroundColor: grey[800], color: '#fff', py: 4, mt: 10 }}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            О компании
                        </Typography>
                        <Typography variant="body2">
                            TOPMarketing — казахстанская компания, специализирующаяся на всесторонней поддержке бизнеса при взаимодействии с Китаем.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Контакты
                        </Typography>
                        <Typography variant="body2">
                            Адрес: Казахстан, г. Астана, ул. Сарайшык д. 34<br />
                            Телефон: +77057747760<br />
                            Email: info@topmarketing.kz
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Быстрые ссылки
                        </Typography>
                        <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>
                            Главная
                        </Link>
                        <Link onClick={() => { navigate('/about'); window.scrollTo(0, 0)  }} color="inherit" sx={{ display: 'block', mb: 1, cursor: 'pointer' }}>
                            О нас
                        </Link>
                        <Link href="#" color="inherit" sx={{ display: 'block' }}>
                            Услуги
                        </Link>
                    </Grid>
                </Grid>
                <Box textAlign="center" sx={{ mt: 4 }}>
                    <Typography variant="body2">
                        © {new Date().getFullYear()} TOPMarketing. Все права защищены.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};


export default Footer;
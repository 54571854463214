import {Box} from "@mui/material";
import { Container, Typography, Paper, Grid, TextField, Button } from '@mui/material';
import {Phone, Email, LocationOn, Build, Agriculture, Grass} from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Instruments = () => {
    return (
        <Container>
            {/* Заголовок страницы */}
            <Box sx={{ mt: 5, mb: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    Садовые инструменты
                </Typography>
            </Box>

            {/* Описание страницы */}
            <Box mb={4}>
                <Typography variant="body1" paragraph>
                    Мы предлагаем широкий ассортимент садовых инструментов, которые помогут вам эффективно работать на вашем участке.
                    Наша продукция включает в себя как профессиональные, так и бытовые решения для садоводов и фермеров.
                </Typography>
            </Box>

            {/* Типы садовых инструментов */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Типы садовых инструментов
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Grass color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Газонокосилки
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Наши газонокосилки идеально подходят для ухода за газоном, обеспечивая быструю и качественную стрижку травы.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Build color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Электроинструменты
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Электроинструменты для сада, такие как триммеры, культиваторы и другие, сделают работу быстрее и эффективнее.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Agriculture color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Ручные инструменты
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Наши ручные инструменты, такие как лопаты, грабли и секаторы, разработаны для обеспечения максимального удобства и эффективности.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Преимущества садовых инструментов */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Почему выбирают наши садовые инструменты?
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Grass color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Надёжность
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Все наши инструменты проходят строгие проверки качества, что гарантирует их долговечность и высокую производительность.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Build color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Эргономичный дизайн
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Мы разрабатываем инструменты с учётом удобства использования, что снижает усталость и увеличивает производительность.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Agriculture color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Эффективность
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Наши садовые инструменты разработаны для максимальной эффективности, что позволяет сократить время работы на участке.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* CTA: Запрос консультации */}
            <Box textAlign="center" mb={5}>
                <Button
                    variant="contained"
                    color="success"
                    size="large"
                    startIcon={<Agriculture />}
                    href="https://wa.me/77057747760" // Ссылка на консультацию в WhatsApp
                    target="_blank"
                    sx={{
                        fontSize: '1rem',
                        padding: '10px 20px',
                        borderRadius: '8px',
                        backgroundColor: '#25D366',
                        '&:hover': {
                            backgroundColor: '#20b354',
                        },
                    }}
                >
                    Запросить консультацию
                </Button>
            </Box>
        </Container>
    )
}

export default Instruments;
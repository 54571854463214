import { Container, Typography, Box, Paper, Grid, Button } from '@mui/material';
import { ElectricCar, Speed, BatteryChargingFull } from '@mui/icons-material';
import { Grass, Build, Agriculture } from '@mui/icons-material';

const Cars = () => {
    return (
        <Container>
            {/* Заголовок страницы */}
            <Box sx={{ mt: 5, mb: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    Электромобили из Китая
                </Typography>
            </Box>

            {/* Описание страницы */}
            <Box mb={4}>
                <Typography variant="body1" paragraph>
                    Электромобили из Китая становятся всё более популярными благодаря их экологичности, инновационным технологиям и доступной цене. Мы предлагаем широкий ассортимент электромобилей от ведущих китайских производителей, которые удовлетворят потребности как личного, так и коммерческого транспорта.
                </Typography>
            </Box>

            {/* Типы электромобилей */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Типы электромобилей
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <ElectricCar color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Легковые электромобили
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Идеально подходят для повседневных поездок, обеспечивая комфорт и экологичность. Модели с запасом хода до 400 км.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <ElectricCar color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Коммерческие электромобили
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Идеально для городских служб и компаний. Удобны для грузоперевозок и доставки в пределах города.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <ElectricCar color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Электромобили для каршеринга
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Компактные и эффективные электромобили для каршеринговых сервисов, с минимальными затратами на обслуживание.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Преимущества электромобилей */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Почему выбирают электромобили из Китая?
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Speed color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Высокая производительность
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Китайские электромобили оснащены мощными двигателями, которые обеспечивают отличное ускорение и плавность хода.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <BatteryChargingFull color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Долговечные батареи
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Надёжные и долговечные аккумуляторы, обеспечивающие большой запас хода и быструю зарядку.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <ElectricCar color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Экологичность
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Электромобили — это отличный способ снизить углеродный след и заботиться об окружающей среде.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* CTA: Запрос консультации */}
            <Box textAlign="center" mb={5}>
                <Button
                    variant="contained"
                    color="success"
                    size="large"
                    startIcon={<ElectricCar />}
                    href="https://wa.me/77057747760" // Ссылка на консультацию в WhatsApp
                    target="_blank"
                    sx={{
                        fontSize: '1rem',
                        padding: '10px 20px',
                        borderRadius: '8px',
                        backgroundColor: '#25D366',
                        '&:hover': {
                            backgroundColor: '#20b354',
                        },
                    }}
                >
                    Запросить консультацию
                </Button>
            </Box>
        </Container>
    )
}

export default Cars;
import {Box} from "@mui/material";
import { Container, Typography, Paper, Grid, TextField, Button } from '@mui/material';
import {
    Phone,
    Email,
    LocationOn,
    Build,
    Agriculture,
    Grass,
    BusinessCenter,
    Assessment,
    MonetizationOn, Gavel
} from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Support = () => {
    return (
        <Container>
            {/* Заголовок страницы */}
            <Box sx={{ mt: 5, mb: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    Бизнес-сопровождение
                </Typography>
            </Box>

            {/* Описание страницы */}
            <Box mb={4}>
                <Typography variant="body1" paragraph>
                    Наша команда предлагает профессиональные услуги по бизнес-сопровождению, включая анализ рынка, юридическую поддержку, финансовое консультирование и стратегическое планирование. Мы помогаем компаниям достигать своих целей, минимизируя риски и повышая эффективность.
                </Typography>
            </Box>

            {/* Наши услуги */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Наши услуги
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Assessment color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Анализ рынка
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Предоставляем глубокий анализ рынка для принятия эффективных решений и достижения конкурентного преимущества.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Gavel color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Юридическая поддержка
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Обеспечиваем полное юридическое сопровождение сделок, включая договоры, регистрацию и соответствие требованиям.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <MonetizationOn color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Финансовое консультирование
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Оказываем поддержку в вопросах финансового планирования, анализа и оптимизации денежных потоков.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <BusinessCenter color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Стратегическое планирование
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Помогаем создавать стратегические планы для долгосрочного роста и устойчивого развития вашего бизнеса.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Преимущества работы с нами */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Почему выбирают нас?
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Assessment color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Опыт и профессионализм
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Наша команда состоит из профессионалов с обширным опытом в бизнес-консультировании.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Gavel color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Надёжность
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Мы обеспечиваем надёжное сопровождение на всех этапах бизнеса, гарантируя безопасность и законность сделок.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <BusinessCenter color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Индивидуальный подход
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Мы разрабатываем уникальные решения, адаптированные под потребности каждого клиента.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* CTA: Запрос консультации */}
            <Box textAlign="center" mb={5}>
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<WhatsAppIcon />}
                    href="https://wa.me/77057747760" // Ссылка на ваш WhatsApp (замените на ваш номер)
                    target="_blank" // Открыть в новой вкладке
                    sx={{
                        marginRight: '6px',
                        backgroundColor: '#25D366', // Цвет кнопки, как у WhatsApp
                        '&:hover': {
                            backgroundColor: '#20b354', // Цвет при наведении
                        },
                        fontSize: '16px'
                    }}
                >
                    Запросить консультацию
                </Button>
            </Box>
        </Container>
    )
}

export default Support;
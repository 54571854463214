import {Box} from "@mui/material";
import { Container, Typography, Paper, Grid, TextField, Button } from '@mui/material';
import {
    Phone,
    Email,
    LocationOn,
    Build,
    Agriculture,
    Grass,
    SupportAgent,
    Assignment,
    Verified, Security
} from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Certificates = () => {
    return (
        <Container>
            {/* Заголовок страницы */}
            <Box sx={{ mt: 5, mb: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    Индустриальные сертификаты
                </Typography>
            </Box>

            {/* Описание страницы */}
            <Box mb={4}>
                <Typography variant="body1" paragraph>
                    Мы предоставляем поддержку в получении и оформлении индустриальных сертификатов, необходимых для обеспечения качества, безопасности и надёжности вашей продукции. Наши услуги включают консультации и сопровождение процесса сертификации.
                </Typography>
            </Box>

            {/* Типы индустриальных сертификатов */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Типы сертификатов
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Verified color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Сертификат качества
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Гарантия высокого качества продукции и соответствия международным стандартам.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Security color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Сертификат безопасности
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Обеспечение безопасности продукции для потребителей и соответствие нормам охраны труда.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Assignment color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Экологический сертификат
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Подтверждение экологичности продукции и минимального воздействия на окружающую среду.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Преимущества сертификации */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Преимущества сертификации
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Verified color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Повышение доверия
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Сертификация повышает доверие клиентов и партнёров к вашему бизнесу.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Security color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Соответствие стандартам
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Сертификация подтверждает, что продукция соответствует международным стандартам.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Assignment color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Конкурентное преимущество
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Наличие сертификатов делает продукцию более привлекательной на рынке.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* CTA: Запрос консультации */}
            <Box textAlign="center" mb={5}>
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<WhatsAppIcon />}
                    href="https://wa.me/77057747760" // Ссылка на ваш WhatsApp (замените на ваш номер)
                    target="_blank" // Открыть в новой вкладке
                    sx={{
                        marginRight: '6px',
                        backgroundColor: '#25D366', // Цвет кнопки, как у WhatsApp
                        '&:hover': {
                            backgroundColor: '#20b354', // Цвет при наведении
                        },
                        fontSize: '16px'
                    }}
                >
                    Запросить консультацию
                </Button>
            </Box>
        </Container>
    )
}

export default Certificates;
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import { BusinessCenter, LocalShipping, SyncAlt } from '@mui/icons-material';

const AboutUs = () => {
    return (
        <Container>
            {/* Заголовок страницы */}
            <Box sx={{ mt: 5, mb: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    О нас
                </Typography>
            </Box>

            {/* Описание компании */}
            <Box mb={4}>
                <Paper elevation={3} sx={{ padding: 4, borderRadius: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        О компании
                    </Typography>
                    <Typography variant="body1" paragraph>
                        TOPMarketing — казахстанская компания, специализирующаяся на всесторонней поддержке бизнеса при взаимодействии с Китаем.
                        Мы предлагаем комплексное решение для организации и сопровождения международных сделок, обеспечивая надёжную логистическую поддержку
                        на всех этапах. Наша деятельность охватывает управление цепочками поставок, что позволяет клиентам получать необходимые товары и услуги
                        в кратчайшие сроки и с минимальными затратами.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Мы стремимся сделать сотрудничество между Казахстаном и Китаем максимально эффективным, предоставляя клиентам профессиональные услуги,
                        адаптированные к их индивидуальным потребностям. Благодаря нашему опыту и глубокому пониманию особенностей китайского рынка, мы обеспечиваем
                        успешное и долгосрочное партнёрство.
                    </Typography>
                </Paper>
            </Box>

            {/* Преимущества компании */}
            <Box mb={5}>
                <Typography variant="h6" gutterBottom align="center">
                    Почему выбирают нас
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <BusinessCenter color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" component="h3" gutterBottom>
                                Комплексная поддержка
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Мы предоставляем полный спектр услуг по поддержке бизнеса — от поиска партнёров до сопровождения сделок.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <LocalShipping color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" component="h3" gutterBottom>
                                Логистическая поддержка
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Наши услуги включают организацию эффективной логистики, что позволяет сократить сроки доставки и снизить расходы.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <SyncAlt color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" component="h3" gutterBottom>
                                Управление цепочками поставок
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Мы управляем цепочками поставок, гарантируя, что ваш бизнес получит необходимые товары вовремя и с минимальными затратами.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Миссия и ценности */}
            <Box mb={5}>
                <Typography variant="h6" gutterBottom>
                    Наша миссия
                </Typography>
                <Paper elevation={3} sx={{ padding: 4, borderRadius: 4 }}>
                    <Typography variant="body1" paragraph>
                        Наша миссия заключается в том, чтобы создавать условия для успешного и долгосрочного сотрудничества между Казахстаном и Китаем.
                        Мы помогаем нашим клиентам развивать бизнес, минимизируя риски и оптимизируя процессы.
                    </Typography>
                </Paper>
            </Box>

            {/* Основные направления деятельности */}
            <Box mb={5}>
                <Typography variant="h6" gutterBottom>
                    Наши направления
                </Typography>
                <Paper elevation={3} sx={{ padding: 4, borderRadius: 4 }}>
                    <Typography variant="body1" paragraph>
                        Мы работаем в нескольких ключевых направлениях:
                    </Typography>
                    <ul>
                        <li>Зарядные станции для электромобилей</li>
                        <li>Аккумуляторы</li>
                        <li>Автомобили из Китая (Электромобили и ДВС)</li>
                        <li>Профессиональные и садовые инструменты</li>
                        <li>Бизнес-сопровождение</li>
                        <li>Партнерство и дилерство</li>
                        <li>Индустриальные сертификаты</li>
                        <li>Разработка сметной документации</li>
                    </ul>
                </Paper>
            </Box>

            {/* Заключение */}
            <Box mb={5}>
                <Typography variant="h6" gutterBottom>
                    Заключение
                </Typography>
                <Paper elevation={3} sx={{ padding: 4, borderRadius: 4 }}>
                    <Typography variant="body1" paragraph>
                        TOPMarketing стремится стать вашим надёжным партнером в международных сделках. Наши знания и опыт на китайском рынке позволяют нам предлагать
                        профессиональные решения, которые помогают нашим клиентам достигать успеха в их бизнесе.
                    </Typography>
                </Paper>
            </Box>
        </Container>
    )
}


export default AboutUs;
import HeaderContainer from "../Header/HeaderContainer";
import MainPageContainer from "../MainPage/MainPageContainer";
import Footer from "../MainPage/Footer";
import {Route, Routes} from "react-router-dom";
import AboutUsContainer from "../AboutUs/AboutUsContainer";
import ChargersContainer from "../Chargers/ChargersContainer";
import InstrumentsContainer from "../Instruments/InstrumentsContainer";
import CarsContainer from "../Cars/CarsContainer";
import BatteriesContainer from "../Batteries/BatteriesContainer";
import ContactUsContainer from "../ContactUs/ContactUsContainer";
import SupportContainer from "../Support/SupportContainer";
import PartnershipContainer from "../Partnership/PartnershipContainer";
import CertificatesContainer from "../Certificates/CertificatesContainer";
import DocumentationContainer from "../Documentation/DocumentationContainer";



const Main = () => {
    return (
        <>
            <HeaderContainer/>
            <Routes>
                <Route path={'/'} element={<MainPageContainer/>}></Route>
                <Route path={'/contacts'} element={<ContactUsContainer/>}></Route>
                <Route path={'/about'} element={<AboutUsContainer/>}></Route>
                <Route path={'/chargers'} element={<ChargersContainer/>}></Route>
                <Route path={'/instruments'} element={<InstrumentsContainer/>}></Route>
                <Route path={'/cars'} element={<CarsContainer/>}></Route>
                <Route path={'/batteries'} element={<BatteriesContainer/>}></Route>
                <Route path={'/support'} element={<SupportContainer/>}></Route>
                <Route path={'/partnership'} element={<PartnershipContainer/>}></Route>
                <Route path={'/certificates'} element={<CertificatesContainer/>}></Route>
                <Route path={'/documentation'} element={<DocumentationContainer/>}></Route>
            </Routes>
            <Footer/>
        </>
    )
}



export default Main;
import {Box} from "@mui/material";
import { Container, Typography, Paper, Grid, TextField, Button } from '@mui/material';
import {Phone, Email, LocationOn, Description, Gavel, Calculate} from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Documentation = () => {
    return (
        <Container>
            {/* Заголовок страницы */}
            <Box sx={{ mt: 5, mb: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    Разработка сметной документации
                </Typography>
            </Box>

            {/* Описание страницы */}
            <Box mb={4}>
                <Typography variant="body1" paragraph>
                    Наша команда предлагает профессиональные услуги по разработке сметной документации для различных проектов, включая строительство, ремонт и модернизацию. Мы обеспечиваем точные расчеты и соответствие нормативным требованиям для эффективного управления бюджетом.
                </Typography>
            </Box>

            {/* Услуги по разработке сметной документации */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Наши услуги
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Calculate color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Сметы на строительство
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Подготовка смет для строительных проектов любого масштаба с учетом всех расходов и нормативов.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Gavel color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Сметы на ремонт
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Разработка смет на ремонтные работы, с учётом затрат на материалы, трудозатрат и оборудования.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Description color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Спецификации и отчёты
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Подготовка технических спецификаций и отчетов для обеспечения полной прозрачности проекта.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Преимущества наших услуг */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Преимущества наших услуг
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Calculate color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Точные расчеты
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Обеспечиваем точные расчеты, которые позволяют избежать перерасходов и оптимизировать бюджет.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Gavel color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Соответствие требованиям
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Сметная документация соответствует всем правовым и строительным нормам.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Description color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Прозрачность проекта
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Полная прозрачность и подробные спецификации, что повышает доверие и уверенность в проекте.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            <Box textAlign="center" mb={5}>
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<WhatsAppIcon />}
                    href="https://wa.me/77057747760" // Ссылка на ваш WhatsApp (замените на ваш номер)
                    target="_blank" // Открыть в новой вкладке
                    sx={{
                        marginRight: '6px',
                        backgroundColor: '#25D366', // Цвет кнопки, как у WhatsApp
                        '&:hover': {
                            backgroundColor: '#20b354', // Цвет при наведении
                        },
                        fontSize: '16px'
                    }}
                >
                    Запросить консультацию
                </Button>
            </Box>
        </Container>
    )
}

export default Documentation;
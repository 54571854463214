import { Container, Typography, Box, Paper, Grid, Button } from '@mui/material';
import { EvStation, FlashOn, Speed } from '@mui/icons-material';

const Chargers = () => {
    return (
        <Container>
            {/* Заголовок страницы */}
            <Box sx={{ mt: 5, mb: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    Зарядные станции для электромобилей
                </Typography>
            </Box>

            {/* Описание страницы */}
            <Box mb={4}>
                <Typography variant="body1" paragraph>
                    В современном мире электромобили становятся всё более популярными, и наличие удобных и надёжных зарядных станций — это важный аспект для их использования.
                    Мы предлагаем зарядные станции различных уровней, которые удовлетворят все потребности владельцев электромобилей.
                </Typography>
            </Box>

            {/* Типы зарядных станций */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Типы зарядных станций
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <EvStation color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Уровень 1
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Зарядка через обычные розетки 120 В. Идеально подходит для домашнего использования.
                                Время зарядки: 8-12 часов для полной зарядки.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <EvStation color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Уровень 2
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Зарядка через специальные зарядные станции на 240 В. Отличный выбор для коммерческих и домашних зарядных станций.
                                Время зарядки: 4-6 часов.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <FlashOn color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Быстрая зарядка (DC Fast)
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Быстрая зарядка через станции постоянного тока (DC). Идеально для зарядных станций вдоль дорог.
                                Время зарядки: 30-60 минут.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Преимущества зарядных станций */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Почему выбирают наши зарядные станции?
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Speed color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Скорость
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Наши зарядные станции обеспечивают быстрое и эффективное зарядное время, что значительно экономит время владельцам электромобилей.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <EvStation color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Надежность
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Мы предлагаем только самые надежные решения, которые проверены временем и соответствуют мировым стандартам безопасности.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <FlashOn color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Энергоэффективность
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Наши зарядные станции максимально используют энергию, что позволяет эффективно управлять затратами на электроэнергию.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* CTA: Запрос консультации */}
            <Box textAlign="center" mb={5}>
                <Button
                    variant="contained"
                    color="success"
                    size="large"
                    startIcon={<FlashOn />}
                    href="https://wa.me/77057747760" // Ссылка на консультацию в WhatsApp
                    target="_blank"
                    sx={{
                        fontSize: '1rem',
                        padding: '10px 20px',
                        borderRadius: '8px',
                        backgroundColor: '#25D366',
                        '&:hover': {
                            backgroundColor: '#20b354',
                        },
                    }}
                >
                    Запросить консультацию
                </Button>
            </Box>
        </Container>
    )
}

export default Chargers;
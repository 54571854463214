import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Container, IconButton, Drawer, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight, Menu as MenuIcon, ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import logo from '../../images/topmklogo.png'

const Header = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false); // Состояние для мобильного меню
    const [expanded, setExpanded] = useState(false); // Состояние для аккордеона в Drawer

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isMenuOpen = Boolean(anchorEl);

    const toggleDrawer = (open) => (event) => {
        setDrawerOpen(open);
    };

    const handleAccordionToggle = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleOpenWhatsApp = () => {
        window.open('https://wa.me/77057747760')
        setDrawerOpen(false)
    }

    // Функция для закрытия Drawer только при выборе пунктов меню
    const handleMenuItemClick = () => {
        setDrawerOpen(false);
    };

    const navigate = useNavigate();

    const handleGoToPage = (path) => {
        navigate(path)
        setDrawerOpen(false)
    }

    const links = [
        {
            title: 'Зарядные станции для электромобилей',
            link: '/chargers'
        },
        {
            title: 'Аккумуляторы',
            link: '/batteries'
        },
        {
            title: 'Автомобили из Китая (Электромобили и ДВС)',
            link: '/cars'
        },
        {
            title: 'Профессиональные и садовые инструменты',
            link: '/instruments'
        },
        {
            title: 'Бизнес-сопровождение',
            link: '/support'
        },
        {
            title: 'Партнерство и дилерство',
            link: '/partnership'
        },
        {
            title: 'Индустриальные сертификаты',
            link: '/certificates'
        },
        {
            title: 'Разработка сметной документации',
            link: '/documentation'
        },
    ]

    // Мобильное меню с аккордеоном для списка услуг
    const mobileMenu = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
        >
            <List>
                <ListItem button component="a" onClick={() => handleGoToPage('/')}>
                    <ListItemText primary="Главная" />
                </ListItem>
                <ListItem button component="a" onClick={() => handleGoToPage('/about')}>
                    <ListItemText primary="О нас" />
                </ListItem>
                {/* Аккордеон для списка услуг */}
                <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionToggle('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Услуги</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {
                                links.map(
                                    x =>
                                        <ListItem button onClick={() => handleGoToPage(x.link)}>
                                            <ListItemText primary={x.title} />
                                        </ListItem>
                                )
                            }
                        </List>
                    </AccordionDetails>
                </Accordion>
                <ListItem button component="a" onClick={() => handleGoToPage('/contacts')}>
                    <ListItemText primary="Контакты" />
                </ListItem>
                <ListItem sx={{
                    backgroundColor: '#25D366', // Цвет кнопки, как у WhatsApp
                    '&:hover': {
                        backgroundColor: '#20b354', // Цвет при наведении
                    },
                }} button component="a" onClick={handleOpenWhatsApp}>
                    <WhatsAppIcon sx={{ color: 'white', marginRight: '6px' }}/>
                    <Typography sx={{ color: 'white' }}>Консультация</Typography>

                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <AppBar position="static">
                <Container>
                    <Toolbar>
                        {/* Логотип или название сайта */}
                        <img style={{ width: '40px', height: '40px', borderRadius: '10px', marginRight: '10px' }} src={logo}/>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                            TOPMK
                        </Typography>

                        {/* Меню для ПК */}
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            <Button sx={{ marginRight: '6px' }} color="inherit" onClick={() => handleGoToPage('/')}>
                                Главная
                            </Button>
                            <Button sx={{ marginRight: '6px' }} color="inherit" onClick={() => handleGoToPage('/about')}>
                                О нас
                            </Button>

                            {/* Кнопка с выпадающим меню "Услуги" */}
                            <Button
                                sx={{ marginRight: '6px' }}
                                color="inherit"
                                aria-controls="services-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                endIcon={isMenuOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                            >
                                Услуги
                            </Button>
                            <Menu
                                id="services-menu"
                                anchorEl={anchorEl}
                                open={isMenuOpen}
                                onClose={handleClose}
                            >
                                {
                                    links.map(x =>
                                        <MenuItem onClick={() => handleGoToPage(x.link)} component="a">
                                            {x.title}
                                        </MenuItem>
                                    )
                                }
                            </Menu>

                            <Button onClick={() => handleGoToPage('/contacts')} sx={{ marginRight: '6px' }} color="inherit">
                                Контакты
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                startIcon={<WhatsAppIcon />}
                                href="https://wa.me/77057747760" // Ссылка на ваш WhatsApp (замените на ваш номер)
                                target="_blank" // Открыть в новой вкладке
                                sx={{
                                    marginRight: '6px',
                                    backgroundColor: '#25D366', // Цвет кнопки, как у WhatsApp
                                    '&:hover': {
                                        backgroundColor: '#20b354', // Цвет при наведении
                                    },
                                }}
                            >
                                Консультация
                            </Button>
                        </Box>

                        {/* Иконка для мобильного меню */}
                        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={toggleDrawer(true)}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            {/* Drawer для мобильного меню */}
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                {mobileMenu}
            </Drawer>
        </>
    );
};

export default Header;

import {Box} from "@mui/material";
import { Container, Typography, Paper, Grid, TextField, Button } from '@mui/material';
import {
    Phone,
    Email,
    LocationOn,
    Build,
    Agriculture,
    Grass,
    BusinessCenter,
    Assessment,
    MonetizationOn, Gavel, Storefront, Group, Handshake, SupportAgent
} from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Partnership = () => {
    return (
        <Container>
            {/* Заголовок страницы */}
            <Box sx={{ mt: 5, mb: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    Партнерство и Дилерство
                </Typography>
            </Box>

            {/* Описание страницы */}
            <Box mb={4}>
                <Typography variant="body1" paragraph>
                    Мы приглашаем компании к сотрудничеству, предлагая выгодные условия для дилеров и партнеров. Наша программа партнерства позволяет развивать бизнес совместно, предоставляя доступ к лучшим условиям и поддержке на рынке.
                </Typography>
            </Box>

            {/* Возможности партнерства */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Возможности для партнеров
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Storefront color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Дилерство
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Станьте официальным представителем нашей продукции и получайте доступ к эксклюзивным условиям продаж.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Group color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Совместные проекты
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Реализуйте совместные проекты и расширяйте бизнес, используя наши ресурсы и поддержку.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Handshake color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Поддержка партнёров
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Мы предлагаем всестороннюю поддержку, чтобы помочь нашим партнерам в продвижении продукции и развитии бизнеса.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Преимущества партнерства */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Почему работать с нами?
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <SupportAgent color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Постоянная поддержка
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Наши специалисты всегда готовы помочь вам с вопросами, связанными с продажами и технической поддержкой.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Storefront color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Эксклюзивные условия
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Партнёры и дилеры получают особые условия на продукцию и услуги, доступные только через нашу сеть.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <Handshake color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Долгосрочные отношения
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Мы стремимся к установлению долгосрочных отношений, основанных на взаимном доверии и успехе.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* CTA: Запрос консультации */}
            <Box textAlign="center" mb={5}>
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<WhatsAppIcon />}
                    href="https://wa.me/77057747760" // Ссылка на ваш WhatsApp (замените на ваш номер)
                    target="_blank" // Открыть в новой вкладке
                    sx={{
                        marginRight: '6px',
                        backgroundColor: '#25D366', // Цвет кнопки, как у WhatsApp
                        '&:hover': {
                            backgroundColor: '#20b354', // Цвет при наведении
                        },
                        fontSize: '16px'
                    }}
                >
                    Запросить консультацию
                </Button>
            </Box>
        </Container>
    )
}

export default Partnership;
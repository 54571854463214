import './App.css';
import * as React from 'react'
import {connect, Provider} from "react-redux";
import MainContainer from "./components/Main/MainContainer";
import store from "./redux/redux-store";
import {HashRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import {blue, green, deepPurple, red, grey} from "@mui/material/colors";


const theme = createTheme({
    palette: {
        primary: {
            main: red[800],
        },
        secondary: {
            main: grey[800],
        },
    },
});

const App = React.memo(({}) => {

  return (
      <MainContainer/>
  )
})

const mapStateToProps = (state) => {
  return {

  }
}

let AppContainer = connect(mapStateToProps, { })(App);

let Application = () => {
  return (
        <ThemeProvider theme={theme}>
            <HashRouter>
                <Provider store={store}>
                    <AppContainer/>
                </Provider>
            </HashRouter>
        </ThemeProvider>
  )
}



export default Application;

import { Container, Typography, Box, Paper, Grid, Button } from '@mui/material';
import { BatteryChargingFull, BatteryStd, FlashOn } from '@mui/icons-material';


const Batteries = () => {
    return (
        <Container>
            {/* Заголовок страницы */}
            <Box sx={{ mt: 5, mb: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    Аккумуляторы для электромобилей
                </Typography>
            </Box>

            {/* Описание страницы */}
            <Box mb={4}>
                <Typography variant="body1" paragraph>
                    Мы предлагаем широкий ассортимент аккумуляторов для электромобилей и гибридов,
                    подходящих для разных моделей и условий эксплуатации. Наши аккумуляторы обеспечивают максимальную производительность,
                    долговечность и энергоэффективность, позволяя вашим транспортным средствам работать на оптимальном уровне.
                </Typography>
            </Box>

            {/* Типы аккумуляторов */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Типы аккумуляторов
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <BatteryChargingFull color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Литий-ионные аккумуляторы
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Самый распространённый тип аккумуляторов, обеспечивающий высокую плотность энергии и длительный срок службы.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <BatteryStd color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Никель-металлогидридные аккумуляторы
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Аккумуляторы с высокой степенью надёжности и долговечности, используемые в гибридных транспортных средствах.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <FlashOn color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Твердотельные аккумуляторы
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Новый тип аккумуляторов с улучшенными характеристиками безопасности, более быстрой зарядкой и увеличенным сроком службы.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Преимущества аккумуляторов */}
            <Box mb={5}>
                <Typography variant="h5" gutterBottom>
                    Почему выбирают наши аккумуляторы?
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <BatteryChargingFull color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Высокая плотность энергии
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Наши аккумуляторы обеспечивают максимальную плотность энергии, что увеличивает запас хода электромобилей.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <BatteryStd color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Надёжность и безопасность
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Аккумуляторы прошли строгие проверки на надёжность и соответствуют самым высоким стандартам безопасности.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper elevation={4} sx={{ padding: 3, borderRadius: 4, textAlign: 'center' }}>
                            <FlashOn color="primary" sx={{ fontSize: 50, mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                Быстрая зарядка
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Мы предлагаем аккумуляторы, которые поддерживают быструю зарядку, что экономит ваше время и позволяет быстрее вернуться на дорогу.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* CTA: Запрос консультации */}
            <Box textAlign="center" mb={5}>
                <Button
                    variant="contained"
                    color="success"
                    size="large"
                    startIcon={<FlashOn />}
                    href="https://wa.me/77057747760" // Ссылка на консультацию в WhatsApp
                    target="_blank"
                    sx={{
                        fontSize: '1rem',
                        padding: '10px 20px',
                        borderRadius: '8px',
                        backgroundColor: '#25D366',
                        '&:hover': {
                            backgroundColor: '#20b354',
                        },
                    }}
                >
                    Запросить консультацию
                </Button>
            </Box>
        </Container>
    )
}

export default Batteries;